<template>
  <div style="padding: 20px;height: auto;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <h1 style="margin-bottom: 50px">星视频订单分天数据</h1>
    <el-form ref="form" :model="preFrom" label-width="80px">
      <el-form-item label="账户ID">
        <el-input v-model="preFrom.advertiser_id" />
        <p style="color: #a9a9a9;margin: 0">必填，聚星首页公司名称下方可见（快手ID）</p>
      </el-form-item>
      <el-form-item label="任务ID">
        <el-input v-model="preFrom.task_ids" type="textarea" />
        <p style="color: #a9a9a9;margin: 0">必填，任务id</p>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          align="right"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00','23:59:59']"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="getDate"
        />
        <p style="color: #a9a9a9;margin: 0">必填，日期格式:yyyy-MM-dd</p>
      </el-form-item>
      <el-form-item style="display: flex; align-items: center;justify-content: center">
        <el-button type="primary" :loading="loading" @click="onSubmit">立即获取</el-button>
        <el-button type="primary" @click="handlerExportRecord">导出记录</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :title="exportRecord.title" :visible.sync="exportRecord.open" append-to-body>
      <el-table v-loading="loading" :data="exportRecord.dataList">
        <el-table-column label="导出账户" align="center" prop="exporterName" />
        <el-table-column label="导出时间" align="center" prop="exportTime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.exportTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件名" align="center" prop="fileName" />
        <el-table-column label="导出状态" align="center" prop="exportState"/>
        <el-table-column label="错误原因" align="center" prop="failReason"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-link :href="scope.row.exportPath">下载</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.size"
          @pagination="getExportList"
      />
    </el-dialog>
  </div>
</template>

<script>
import {investmentDataDaily} from '@/api/starVideos'
import {parseTime} from "@/utils/untilsMethod";
import {exportList} from "@/api/exportFile";

export default {
  data() {
    return {
      loading: false,
      // 订单导出记录
      exportRecord: {
        open: false,
        title: '',
        dataList: []
      },
      total:0,
      queryParams: {exportType:6,page:1,size:20},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      createTime: undefined,
      preFrom: {
        advertiser_id: undefined,
        task_ids: undefined,
        start_time: undefined,
        end_time: undefined
      },
      form: {}
    }
  },
  methods: {
    parseTime,
    handlerExportRecord() {
      return exportList({"exportType":6,"page":1,"size":20}).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
        this.exportRecord.open=true
        this.exportRecord.title="星视频订单分天导出记录"
      })
    },
    getExportList(){
      return exportList(this.queryParams).then(response =>{
        this.total = response.total
        this.exportRecord.dataList=response.rows
      })
    },
    onSubmit() {
      if (!this.preFrom.advertiser_id){
        this.$message.error("账户ID不能为空");
        return;
      }
      if (!this.preFrom.task_ids){
        this.$message.error("任务ID不能为空");
        return;
      }
      if (!this.createTime){
        this.$message.error("创建时间不能为空");
        return;
      }
      this.loading = true
      this.getForm()
      console.log(this.form)
      investmentDataDaily(this.form).then(response => {
        console.log(response)
        this.$message.success(response.msg)
        this.reset()
        this.loading = false
      }).catch(() => {
        this.$message.error('请求失败')
        this.loading = false
      })
    },
    reset() {
      this.form = {}
    },
    getForm() {
      if (this.preFrom.advertiser_id) {
        this.form.advertiser_id = Number(this.preFrom.advertiser_id)
      }
      if (this.preFrom.task_ids) {
        this.form.task_ids = this.preFrom.task_ids.trim().split(/[,\s]+/)
      }
      if (this.preFrom.start_time || this.preFrom.end_time) {
        this.form.start_time = this.preFrom.start_time
        this.form.end_time = this.preFrom.end_time
      }
    },
    getDate(value) {
      if (this.createTime) {
        this.preFrom.start_time = this.createTime[0]
        this.preFrom.end_time = this.createTime[1]
      }
      if (value == null) {
        this.createTime = undefined // 清空开始时间和结束时间
        this.preFrom.start_time = undefined
        this.preFrom.end_time = undefined
      }
    }
  }
}
</script>

